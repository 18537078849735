import { Box, Button } from "@mui/material";
import { CreateTaskDialog } from "./CreateTaskDialog";
import { useState } from "react";

export const TasksActions = () => {
  const [isCreateTaskDialogOpen, setIsCreateTaskDialogOpen] = useState(false);

  const createTaskDialogOpen = () => setIsCreateTaskDialogOpen(true);

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={createTaskDialogOpen}
      >
        Criar tarefa
      </Button>
      <CreateTaskDialog
        open={isCreateTaskDialogOpen}
        setIsCreateTaskDialogOpen={setIsCreateTaskDialogOpen}
      />
    </Box>
  );
};
