import { ApiTypesAgentTask } from "../../../app-types";

export const getPatientGullNameOrPhone = (
  lifeDataAttributes: ApiTypesAgentTask["schemas"]["PatientResource"]["attributes"]
) => {
  const firstName = lifeDataAttributes?.first_name || "";
  const lastName = lifeDataAttributes?.last_name || "";
  if (!firstName && !lastName) return lifeDataAttributes?.phone_number || "";

  return `${firstName} ${lastName}`;
};
