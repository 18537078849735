import { useMutation } from "@tanstack/react-query";
import { agentApi } from "../../../api/agent-api";
import { renderNotifier } from "../../../providers/Notifier";
import { TaskStatus } from "../types/TaskTypes";

export const useChangeTaskStatusMutation = () => {
  const changeTaskStatusMutation = useMutation({
    mutationKey: ["change-task-status"],
    mutationFn: async (params: {
      taskId: string;
      furtherStatus: TaskStatus;
    }) => {
      const { taskId, furtherStatus } = params;
      return agentApi.patch(`/api/v1/tasks/${taskId}`, {
        data: {
          id: taskId,
          type: "tasks",
          attributes: {
            status: furtherStatus,
          },
        },
      });
    },
    onError: (_) => {
      renderNotifier("Ocorreu algum erro ao mudar o status da tarefa", {
        variant: "error",
      });
    },
  });

  return { changeTaskStatusMutation };
};
