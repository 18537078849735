import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";

type TCustomSelectProps = {
  value?: string;
  onChange: (value: SelectChangeEvent<string>) => void;
  label?: string;
  options?: { title: string; value: string }[];
  helperText?: string;
} & SelectProps;

export const CustomSelect = forwardRef((props: TCustomSelectProps, ref) => {
  const labelId = `${props.label?.replace(/ /g, "-")}-labelId}`;
  const { helperText, ...selectProps } = props;
  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id={labelId} error={props.error}>
        {props.label}
      </InputLabel>
      <Select
        {...selectProps}
        ref={ref}
        fullWidth
        labelId={labelId}
        label={props.label}
        variant="outlined"
        MenuProps={{
          autoFocus: false,
        }}
      >
        <MenuItem value="">Selecione</MenuItem>
        {props.options?.map?.((option) => (
          <MenuItem
            value={option.value}
            key={option.value}
            sx={{ textTransform: "capitalize" }}
          >
            {option.title}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText sx={{ mt: 0.5 }}>
          <Typography variant="caption" color="error">
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
});
