import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { PlaylistAddCheckOutlined } from "@mui/icons-material";
import { ScreenHeader } from "../../../components/ScreenHeader";
import { useParams } from "react-router-dom";
import { useGetTaskByIdQuery } from "../api/useGetTaskByIdQuery";
import { SummaryBox } from "../../Chat/components/LifeSummary/components/SummaryBox";
import { formatDate } from "../../Chat/utils/dateUtils";
import { useGetLifeById } from "../../Chat/components/LifeSummary/lifeApi/useGetLifeById";

export const TaskDetailModule = () => {
  const { taskId = "" } = useParams();

  const getTaskByIdQuery = useGetTaskByIdQuery(taskId);
  const taskByIdData = getTaskByIdQuery.getTaskByIdQuery.data?.data?.data;
  // get patient data instead of life
  const getLifeById = useGetLifeById(taskByIdData?.attributes?.agent_id || "");
  const lifeData = getLifeById.data?.data;

  const isLoading =
    getTaskByIdQuery.getTaskByIdQuery.isLoading || getLifeById.isLoading;

  return (
    <Box sx={{ mt: 4, padding: 2 }}>
      <Box sx={{ display: "flex" }}>
        <ScreenHeader title="Tarefas" icon={PlaylistAddCheckOutlined} />
      </Box>
      {!isLoading ? (
        <Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" color="primary">
                Pacientes
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "40%",
              maxWidth: "540px",
            }}
          >
            <SummaryBox
              collapsable={false}
              details={
                <Box>
                  <SDetailRow>
                    <Box>
                      <SRowTitle>CPF</SRowTitle>
                    </Box>
                    <SRowDescription>
                      {lifeData?.attributes?.registration_number}
                    </SRowDescription>
                  </SDetailRow>
                  <SDetailRow>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {lifeData?.attributes?.coverages?.map((coverage) => (
                        <>
                          <Box>
                            <SRowTitle>Carterinha</SRowTitle>

                            <SRowDescription>
                              {coverage?.attributes?.number}
                            </SRowDescription>
                          </Box>
                          <Box>
                            <SRowDescription>
                              <Box>
                                {
                                  coverage?.attributes?.benefit?.attributes
                                    ?.name
                                }
                              </Box>
                              <Box>
                                {
                                  coverage?.attributes?.benefit?.attributes
                                    ?.product
                                }
                              </Box>
                            </SRowDescription>
                          </Box>
                        </>
                      ))}
                    </Box>
                  </SDetailRow>
                  <SDetailRow>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Box>
                          <SRowTitle>Telefones</SRowTitle>
                        </Box>
                        <SRowDescription>
                          {lifeData?.attributes?.phone_numbers
                            ?.map((phone) => phone?.attributes?.number)
                            .join(", ")}
                        </SRowDescription>
                      </Box>
                      <Box>
                        <Box>
                          <SRowTitle>E-mail</SRowTitle>
                        </Box>
                        <SRowDescription>
                          {lifeData?.attributes?.email}
                        </SRowDescription>
                      </Box>
                    </Box>
                  </SDetailRow>
                </Box>
              }
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" color="primary">
                Tarefa
              </Typography>
            </Box>
            <Box
              sx={{
                width: "40%",
                maxWidth: "540px",
              }}
            >
              <SummaryBox
                collapsable={false}
                details={
                  <Box>
                    <SDetailRow>
                      <Box>
                        <SRowTitle>Consulta médica</SRowTitle>
                      </Box>
                      {/* <SRowDescription>
                        {taskByIdData?.attributes?.task_type}
                      </SRowDescription> */}
                    </SDetailRow>
                    <SDetailRow>
                      <Box>
                        <SRowTitle>Descrição</SRowTitle>
                      </Box>
                      <SRowDescription>
                        {taskByIdData?.attributes?.description}
                      </SRowDescription>
                    </SDetailRow>
                    <SDetailRow>
                      <Box>
                        <SRowTitle>Prazo</SRowTitle>
                      </Box>
                      <SRowDescription>
                        {formatDate(
                          taskByIdData?.attributes?.due_date || "",
                          "dd/MM/yyyy"
                        )}
                      </SRowDescription>
                    </SDetailRow>
                    <SDetailRow>
                      <Box>
                        <SRowTitle>Data da criação</SRowTitle>
                      </Box>
                      <SRowDescription>
                        {formatDate(
                          taskByIdData?.attributes?.due_date || "",
                          "dd/MM/yyyy"
                        )}
                      </SRowDescription>
                    </SDetailRow>
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <CircularProgress size={24} />
        </Box>
      )}
    </Box>
  );
};

const SRowTitle = styled(Typography)(({ theme }) => ({
  ...theme?.typography?.button,
  color: "GrayText",
}));

const SRowDescription = styled(Typography)(({ theme }) => ({
  ...theme?.typography?.button,
  color: "black",
}));

const SDetailRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
