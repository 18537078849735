import { useMutation } from "@tanstack/react-query";
import { api } from "../../../api";
import { CreateTaskRequest, TaskResponse, TaskType } from "../types/TaskTypes";
import { TCreateTaskForm } from "../components/CreateTaskDialog/helpers/createTaskSchema";
import { formatDateToBE } from "../../Chat/utils/dateUtils";
import { renderNotifier } from "../../../providers/Notifier";

type CreateTaskAttributes = CreateTaskRequest["data"]["attributes"] & {
  priority: boolean;
}; // TODO: remove this when BE adds priority to the priority to schema

export const useCreateTaskMutation = () => {
  const createTaskMutation = useMutation({
    mutationKey: ["create-task"],
    mutationFn: async (createTaskParams: TCreateTaskForm) => {
      const payload: CreateTaskAttributes = {
        assigned_type: "PRACTITIONER",
        assigned_id: createTaskParams.assignee?.id,
        life_id: createTaskParams.lifeInformation?.id || "",
        description: createTaskParams.description,
        task_type: createTaskParams.taskType as TaskType,
        ...(createTaskParams.endDate && {
          due_date: formatDateToBE(createTaskParams.endDate),
        }),
        priority: createTaskParams.highPriority,
      };

      return api.post<TaskResponse>("/api/v1/tasks", {
        data: {
          type: "tasks",
          attributes: {
            ...payload,
          },
        },
      });
    },
    onError: (_) => {
      renderNotifier("Ocorreu algum erro ao criar a tarefa", {
        variant: "error",
      });
    },
  });

  return { createTaskMutation };
};
