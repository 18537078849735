import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { CustomDialog } from "../../../../components/CustomDialog/CustomDialog";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CustomSelect } from "../../../../components/CustomSelect";

import { zodResolver } from "@hookform/resolvers/zod";
import { LivesAutocompleteField } from "./components/LivesAutocompleteField";
import {
  createTaskFormDefaultValues,
  createTaskSchemeValidation,
  taskTypes,
  TCreateTaskForm,
} from "./helpers/createTaskSchema";
import { useCreateTaskMutation } from "../../api/useCreateTaskMutation";
import { useQueryClient } from "@tanstack/react-query";
import { useChangeTaskStatusMutation } from "../../api/useChangeTaskStatusMutation";

type TCreateTaskDialogProps = {
  setIsCreateTaskDialogOpen: (arg: boolean) => void;
  open: boolean;
};

export const CreateTaskDialog = (props: TCreateTaskDialogProps) => {
  const queryClient = useQueryClient();
  const [isSubmitAndStart, setIsSubmitAndStart] = useState(false); // Track submission type

  const closeModalHandler = () => props.setIsCreateTaskDialogOpen(false);

  const taskForm = useForm<TCreateTaskForm>({
    defaultValues: createTaskFormDefaultValues,
    resolver: zodResolver(createTaskSchemeValidation),
  });

  const { control, handleSubmit } = taskForm;
  const { errors } = taskForm.formState;

  const { createTaskMutation } = useCreateTaskMutation();
  const { changeTaskStatusMutation } = useChangeTaskStatusMutation();

  const onSubmit: SubmitHandler<TCreateTaskForm> = async (data) => {
    const response = await createTaskMutation.mutateAsync(data);
    taskForm.reset(createTaskFormDefaultValues);
    setIsSubmitAndStart(false);
    if (isSubmitAndStart) {
      await changeTaskStatusMutation.mutateAsync({
        taskId: response.data?.data?.id || "",
        furtherStatus: "DOING",
      });
    }

    await queryClient.refetchQueries({
      queryKey: ["get-agent-tasks"],
    });

    props.setIsCreateTaskDialogOpen(false);
  };

  const isLoading =
    createTaskMutation.isPending ||
    Boolean(
      queryClient.isFetching({
        queryKey: ["get-tasks"],
      })
    );

  const isFormValid = taskForm.formState.isValid;

  return (
    <CustomDialog
      open={props.open}
      fullWidth
      showCloseButton
      onClose={closeModalHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4">Criar tarefa</Typography>
        </Box>
        <SFieldSpacing>
          <LivesAutocompleteField form={taskForm} />
        </SFieldSpacing>
        <SFieldSpacing>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Telefone"
                error={!!errors?.phone}
                helperText={errors?.phone?.message}
              />
            )}
          />
        </SFieldSpacing>
        <SFieldSpacing>
          <Controller
            name="taskType"
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                label="Tipo de tarefa"
                options={taskTypes}
                error={!!errors?.taskType}
                helperText={errors?.taskType?.message}
              />
            )}
          />
        </SFieldSpacing>
        <SFieldSpacing>
          <Controller
            name="theContext"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contexto"
                multiline
                rows={2}
                error={!!errors?.theContext}
                helperText={errors?.theContext?.message}
              />
            )}
          />
        </SFieldSpacing>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            type="submit"
            onClick={() => setIsSubmitAndStart(true)}
            disabled={!isFormValid || isLoading}
          >
            Salvar e iniciar tarefa
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isFormValid || isLoading}
          >
            Salvar tarefa
          </Button>
          {isLoading && (
            <Box>
              <CircularProgress size={16} color="primary" />
            </Box>
          )}
        </Box>
      </form>
    </CustomDialog>
  );
};

const SFieldSpacing = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
