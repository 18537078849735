import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { CustomDialog } from "../../../../components/CustomDialog/CustomDialog";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CustomSelect } from "../../../../components/CustomSelect";
import { CustomDatePicker } from "../../../../components/CustomDatePicker";

import { zodResolver } from "@hookform/resolvers/zod";
import { LivesAutocompleteField } from "./components/LivesAutocompleteField";
import {
  createTaskFormDefaultValues,
  createTaskSchemeValidation,
  taskTypes,
  TCreateTaskForm,
} from "./helpers/createTaskSchema";
import { AssigneesAutocompleteField } from "./components/AssigneesAutocompleteField";
import { useCreateTaskMutation } from "../../api/useCreateTaskMutation";
import { useQueryClient } from "@tanstack/react-query";

type TCreateTaskDialogProps = {
  setIsCreateTaskDialogOpen: (arg: boolean) => void;
  open: boolean;
};

export const CreateTaskDialog = (props: TCreateTaskDialogProps) => {
  const queryClient = useQueryClient();
  const [isSubmitAndCreateNew, setIsSubmitAndCreateNew] = useState(false); // Track submission type

  const closeModalHandler = () => props.setIsCreateTaskDialogOpen(false);

  const taskForm = useForm<TCreateTaskForm>({
    defaultValues: createTaskFormDefaultValues,
    resolver: zodResolver(createTaskSchemeValidation),
  });

  const { control, handleSubmit } = taskForm;
  const { errors } = taskForm.formState;

  const { createTaskMutation } = useCreateTaskMutation();

  const onSubmit: SubmitHandler<TCreateTaskForm> = async (data) => {
    await createTaskMutation.mutateAsync(data);
    await queryClient.refetchQueries({
      queryKey: ["get-tasks"],
    });
    taskForm.reset(createTaskFormDefaultValues);
    setIsSubmitAndCreateNew(false);
    if (!isSubmitAndCreateNew) {
      closeModalHandler();
    }
  };

  const isLoading =
    createTaskMutation.isPending ||
    Boolean(
      queryClient.isFetching({
        queryKey: ["get-tasks"],
      })
    );

  const isFormValid = taskForm.formState.isValid;

  return (
    <CustomDialog
      open={props.open}
      fullWidth
      showCloseButton
      onClose={closeModalHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4">Criar tarefa</Typography>
        </Box>
        <Box>
          <SFieldSpacing>
            <LivesAutocompleteField form={taskForm} />
          </SFieldSpacing>
          <SFieldSpacing>
            <Controller
              name="taskType"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  label="Tipo de tarefa"
                  options={taskTypes}
                  error={!!errors?.taskType}
                  helperText={errors?.taskType?.message}
                />
              )}
            />
          </SFieldSpacing>
          <SFieldSpacing>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descrição"
                  multiline
                  rows={2}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                />
              )}
            />
          </SFieldSpacing>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography variant="body1" fontWeight="700">
              Atribuição
            </Typography>
          </Box>
          <SFieldSpacing>
            <AssigneesAutocompleteField form={taskForm} />
          </SFieldSpacing>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 6 }}>
          <Box>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  slotProps={{ field: { clearable: true } }}
                  timezone="America/Sao_Paulo"
                  label="Prazo"
                  defaultValue={field.value}
                  value={field.value}
                  inputRef={field.ref}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  errorMessage={errors?.endDate?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="highPriority"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label={
                      <Typography variant="body2">Prioridade alta</Typography>
                    }
                  />
                </FormGroup>
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            type="submit"
            onClick={() => setIsSubmitAndCreateNew(true)}
            disabled={!isFormValid || isLoading}
          >
            Salvar e criar nova tarefa
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isFormValid || isLoading}
          >
            Salvar tarefa
          </Button>
          {isLoading && (
            <Box>
              <CircularProgress size={16} color="primary" />
            </Box>
          )}
        </Box>
      </form>
    </CustomDialog>
  );
};

const SFieldSpacing = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
