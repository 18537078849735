import { useMutation } from "@tanstack/react-query";
import { CreateTaskRequest, TaskResponse } from "../types/TaskTypes";
import { TCreateTaskForm } from "../components/CreateTaskDialog/helpers/createTaskSchema";
import { renderNotifier } from "../../../providers/Notifier";
import { agentApi } from "../../../api/agent-api";

type CreateTaskAttributes = CreateTaskRequest["data"]["attributes"];

export const useCreateTaskMutation = () => {
  const createTaskMutation = useMutation({
    mutationKey: ["create-task"],
    mutationFn: async (createTaskParams: TCreateTaskForm) => {
      const payload: CreateTaskAttributes = {
        description: createTaskParams.theContext,
        priority: "LOW",
        patient_id: createTaskParams.patient?.id,
      };

      return agentApi.post<TaskResponse>("/api/v1/tasks", {
        data: {
          type: "tasks",
          attributes: {
            ...payload,
          },
        },
      });
    },
    onError: (_) => {
      renderNotifier("Ocorreu algum erro ao criar a tarefa", {
        variant: "error",
      });
    },
  });

  return { createTaskMutation };
};
