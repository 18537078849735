import { Route, Routes } from "react-router-dom";
import { TasksListModule } from "./modules/TasksListModule";
import { TaskDetailModule } from "./modules/TaskDetailModule";

export const TasksScreen = () => {
  return (
    <Routes>
      <Route path="/" Component={TasksListModule} />
      <Route path="/:taskId" Component={TaskDetailModule} />
    </Routes>
  );
};
