import { useQuery } from "@tanstack/react-query";

import { TaskResponseList } from "../types/TaskTypes";
import { agentApi } from "../../../api/agent-api";

export const useGetTasksQuery = () => {
  const getTasksQuery = useQuery({
    queryKey: ["get-agent-tasks"],
    queryFn: async () => {
      return agentApi.get<TaskResponseList>("/api/v1/tasks", {
        params: {
          limit: 100,
        },
      });
    },
  });

  return { getTasksQuery };
};
