import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useGetTasksQuery } from "../../api/useGetTasksQuery";
import { TaskSchema } from "../../types/TaskTypes";
import { formatDate } from "../../../Chat/utils/dateUtils";
import { ptBR } from "@mui/x-data-grid/locales";
import { useNavigate } from "react-router-dom";

const columns: GridColDef<TaskSchema>[] = [
  // {
  //   field: "priority",
  //   headerName: "Prioridade",
  //   flex: 16.6,
  // },
  {
    field: "description",
    headerName: "Contexto",
    flex: 16.6,
  },
  {
    field: "phone",
    headerName: "Telefone",
    flex: 10.6,
  },
  // {
  //   field: "assigned_id",
  //   headerName: "Responsável",
  //   flex: 16.6,
  // },
  {
    field: "patient_id",
    headerName: "Paciente",
    sortable: false,
    flex: 16.6,
  },
  // {
  //   field: "due_date",
  //   headerName: "Prazo",
  //   flex: 16.6,
  // },
  {
    field: "status",
    headerName: "Status",
    flex: 10,
  },
];

export const TasksTable = () => {
  const { getTasksQuery } = useGetTasksQuery();
  const tasksQueryData = getTasksQuery.data?.data?.data || [];
  const navigate = useNavigate();

  const normalizedTasksData = tasksQueryData?.map((task, index) => ({
    id: index,
    ...task.attributes,
    task_id: task?.id,
    due_date: formatDate(task.attributes.due_date || "", "dd/MM/yyyy"),
  }));

  const handleRowClick = (params: GridRowParams<TaskSchema>) => {
    console.log(params.row);
    navigate(`/agent-tasks/${params.row.task_id}`);
  };

  const isLoading = getTasksQuery.isLoading || getTasksQuery.isRefetching;

  return (
    <Box sx={{ padding: 2, minHeight: "50vh" }}>
      {!isLoading ? (
        <DataGrid
          rows={normalizedTasksData}
          onRowClick={handleRowClick}
          columns={columns}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableColumnMenu
          pageSizeOptions={[10, 30]}
          disableRowSelectionOnClick
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            border: 0,
            minWidth: 0,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 900,
              fontFamily: "HafferSQXH-Bold",
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
        />
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={16} />
        </Box>
      )}
    </Box>
  );
};
