import { useQuery } from "@tanstack/react-query";

import { LivesResponse } from "../types/TaskTypes";
import { agentApi } from "../../../api/agent-api";

type TuseGetPatientsQueryParams = {
  searchQuery: string;
};

export const useGetPatientsQuery = ({
  searchQuery,
}: TuseGetPatientsQueryParams) => {
  const getLivesQuery = useQuery({
    queryKey: ["get-lives", searchQuery],
    queryFn: async () => {
      return agentApi.get<LivesResponse>("/api/v1/patients", {
        params: {
          phone_number: searchQuery,
        },
      });
    },
  });

  return getLivesQuery;
};
