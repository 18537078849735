import { z } from "zod";
import { PatientResource, TaskType } from "../../../types/TaskTypes";

export const taskTypes = [
  {
    title: "Outros",
    value: "OTHER",
  },
];

export const createTaskSchemeValidation = z.object({
  patient: z
    .object({})
    .passthrough()
    .nullable()
    .refine((data) => data !== null, {
      message: "O campo Paciente é obrigatório",
    }),
  phone: z.string().min(1, { message: "O campo Telefone é obrigatório" }),
  taskType: z
    .string()
    .min(1, { message: "O campo Tipo de tarefa é obrigatório" }),
  theContext: z.string().min(1, { message: "O campo Contexto é obrigatório" }),
});

export type TCreateTaskForm = {
  patient: PatientResource | null;
  autocompletePatientName: string;
  phone: string;
  taskType: TaskType | string;
  theContext: string;
};

export const createTaskFormDefaultValues: TCreateTaskForm = {
  patient: null,
  autocompletePatientName: "",
  phone: "",
  taskType: "",
  theContext: "",
};
