import { useQuery } from "@tanstack/react-query";
import { TaskResponse } from "../types/TaskTypes";
import { api } from "../../../api";

export const useGetTaskByIdQuery = (taskId: string) => {
  const getTaskByIdQuery = useQuery({
    queryKey: ["get-task-by-id", taskId],
    queryFn: async () => {
      return api.get<TaskResponse>(`/api/v1/tasks/${taskId}`);
    },
  });

  return { getTaskByIdQuery };
};
