import { z } from "zod";
import { LiveResource, TaskType } from "../../../types/TaskTypes";
import { SystemUser } from "../../../../Chat/components/ChatView/components/AssignForm/hooks/useGetSystemUsers";

export const taskTypes = [
  // {
  //   title: "Atestado",
  //   value: "medical_certificate",
  // },
  // {
  //   title: "Envio de receita",
  //   value: "prescription",
  // },
  // {
  //   title: "Encaminhamento",
  //   value: "forwarding",
  // },
  // {
  //   title: "Acompanhamento",
  //   value: "monitoring",
  // },
  // {
  //   title: "Agendamento externo",
  //   value: "external_scheduling",
  // },
  // {
  //   title: "Confirmação de consulta",
  //   value: "APPOINTMENT_CONFIRMATION",
  // },
  {
    title: "Outros",
    value: "OTHER",
  },
];

export const createTaskSchemeValidation = z.object({
  description: z
    .string()
    .min(1, { message: "O campo Descrição é obrigatório" }),
  lifeInformation: z
    .object({})
    .passthrough()
    .nullable()
    .refine((data) => data !== null, {
      message: "O campo Atribuição é obrigatório",
    }),
  assignee: z
    .object({})
    .passthrough()
    .nullable()
    .refine((data) => data !== null, {
      message: "O campo Atribuição é obrigatório",
    }),
  taskType: z
    .string()
    .min(1, { message: "O campo Tipo de tarefa é obrigatório" }),
  endDate: z
    .date()
    .nullable()
    .refine((data) => data !== null, {
      message: "O campo Prazo é obrigatório",
    }),
  highPriority: z.boolean(),
});

export type TCreateTaskForm = {
  autocompleteLifeName: string;
  lifeInformation: LiveResource | null;
  taskType: TaskType | string;
  description: string;
  assignee: SystemUser | null;
  endDate: Date | null;
  highPriority: boolean;
};

export const createTaskFormDefaultValues: TCreateTaskForm = {
  autocompleteLifeName: "",
  lifeInformation: null,
  taskType: "",
  description: "",
  assignee: null,
  endDate: null,
  highPriority: false,
};
