import { useQuery } from "@tanstack/react-query";
import { api } from "../../../api";
import { TaskResponseList } from "../types/TaskTypes";

export const useGetTasksQuery = () => {
  const getTasksQuery = useQuery({
    queryKey: ["get-tasks"],
    queryFn: async () => {
      return api.get<TaskResponseList>("/api/v1/tasks", {
        params: {
          limit: 100,
        },
      });
    },
  });

  return { getTasksQuery };
};
