import { Autocomplete, TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { PatientResource } from "../../../types/TaskTypes";
import { useGetPatientsQuery } from "../../../api/useGetPatientsQuery";
import { useDebounce } from "use-debounce";
import { TCreateTaskForm } from "../helpers/createTaskSchema";
import Search from "@mui/icons-material/Search";
import { getPatientGullNameOrPhone } from "../../../helpers/getPatientFullNameOrPhone";

type TLivesAutocompleteFieldProps = {
  form: UseFormReturn<TCreateTaskForm, unknown, undefined>;
};

export const LivesAutocompleteField = (props: TLivesAutocompleteFieldProps) => {
  const [autocompleteLifeName] = useDebounce(
    props.form.watch("autocompletePatientName"),
    500
  );

  const getLivesQuery = useGetPatientsQuery({
    searchQuery: autocompleteLifeName,
  });

  const { form } = props;
  const { errors } = form.formState;

  const livesData = getLivesQuery?.data?.data?.data;

  const isLoading = getLivesQuery.isLoading;

  return (
    <Controller
      name="patient"
      control={form.control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          /**
           * freeSolo: workaround to show "noOptionsText" when no data is found.
           * This option only works freeSolo is not active
           */

          onChange={(_, data) => {
            field.onChange(data ? data : null);
          }}
          openOnFocus
          options={livesData || []}
          filterOptions={(x) => x}
          noOptionsText="Nenhum dado encontrado"
          loading={isLoading}
          loadingText="Carregando..."
          getOptionLabel={(option) =>
            getPatientGullNameOrPhone((option as PatientResource)?.attributes)
          }
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...form.register("autocompletePatientName")}
              {...params}
              label="Busque paciente por telefone"
              error={!!errors?.patient}
              helperText={errors?.patient?.message as string}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <Search key="search-icon" />
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
