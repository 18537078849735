import { Button, CircularProgress } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { TaskSchema } from "../../../types/TaskTypes";
import { useMutation } from "@tanstack/react-query";
import { renderNotifier } from "../../../../../providers/Notifier";
import { api } from "../../../../../api";

export const AgentButton = (props: GridRenderCellParams<TaskSchema>) => {
  const runAgentMutation = useMutation({
    mutationKey: ["run-agent", props.row?.task_id],
    onMutate: async () => {
      api.post("/api/v1/agents", {
        task_id: props.row?.task_id,
      });
    },
    onError: (_) => {
      renderNotifier("Erro ao executar agente", { variant: "error" });
    },
    onSuccess: () => {
      renderNotifier("Agente executado com sucesso", { variant: "success" });
    },
  });

  const isLoading = runAgentMutation.isPending;

  const onClickHandler = () => {
    runAgentMutation.mutate();
  };

  return (
    <Button
      fullWidth
      variant="contained"
      onClick={(e) => {
        e.stopPropagation();
        onClickHandler();
      }}
      disabled={isLoading}
      endIcon={isLoading && <CircularProgress size={20} />}
    >
      Agente
    </Button>
  );
};
